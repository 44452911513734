<template>
  <div>
    <validation-observer ref="mentorRoleObserver">
      <b-row v-if="isMentee">
        <b-col md="6">
          <b-form-group>
            <label for="i-professional-experience">
              {{ $t('ProfessionalExperience') }} <required-field />
            </label>
            <validation-provider
              v-slot="{ errors }"
              name="professionalExperience"
              rules="required|date_format:MM-yyyy|working_experience"
              mode="lazy"
            >
              <cleave
                id="date"
                v-model="professionalExperience"
                class="form-control"
                :raw="false"
                :options="cleaveOptions.date"
                :placeholder="$t('MM-YYYY')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <label for="i-mentoring-experience">
              {{ $t('MentoringExperience') }} <required-field />
            </label>
            <validation-provider
              v-slot="{ errors }"
              name="mentoringExperience"
              rules="required|date_format:MM-yyyy|working_experience"
              mode="lazy"
            >
              <cleave
                id="date"
                v-model="mentoringExperience"
                class="form-control"
                :raw="false"
                :options="cleaveOptions.date"
                :placeholder="$t('MM-YYYY')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="mentoringReason"
            rules="required|max:1400"
            mode="lazy"
          >
            <b-form-group>
              <label for="i-mentoring-reason">
                {{ $t('MentoringReason') }} <required-field />
              </label>
              <textarea-with-counter
                id="i-mentoring-reason"
                v-model="mentoringReason"
                no-resize
                :max-chars="1400"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="menteeWishlist"
            rules="required|max:1400"
            mode="lazy"
          >
            <b-form-group>
              <label for="i-mentee-wishlist">
                {{ $t('MenteeWishlist') }} <required-field />
              </label>
              <b-form-text>
                {{ $t('MenteeWishlistMore') }}
              </b-form-text>
              <textarea-with-counter
                id="i-mentee-wishlist"
                v-model="menteeWishlist"
                no-resize
                :max-chars="1400"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <validation-provider
            v-slot="{ errors }"
            name="fieldOfSupport"
            rules="required"
            mode="lazy"
          >
            <b-form-group :description="$t('You can select more than one fields')">
              <label>
                {{ $t('MentorRegistrationFieldOfSupport') }} <required-field />
              </label>
              <v-select
                v-model="translatedFieldsOfSupport"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                :placeholder="$t('Choose')"
                label="text"
                :options="translatedFieldOfSupportOptions"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormText,
  BFormTextarea,
} from 'bootstrap-vue'
import { required, max, date_format, working_experience } from '@mentoring-platform-validations'
import RequiredField from '@mentoring-platform/views/components/blocks/RequiredField.vue'
import TextareaWithCounter from '@mentoring-platform/views/components/blocks/TextareaWithCounter.vue'
import { useFieldOfSupportRepository } from '@mentoring-platform/composables'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormText,
    BFormTextarea,
    Cleave,
    RequiredField,
    TextareaWithCounter,
    ValidationObserver,
    ValidationProvider,
    vSelect
  },
  props: {
    mentor: {
      type: Object
    }
  },
  data() {
    return {
      professionalExperience: null,
      mentoringExperience: null,
      mentoringReason: null,
      menteeWishlist: null,
      fieldsOfSupport: [],
      cleaveOptions: {
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['m', 'Y']
        },
      },
    }
  },
  computed: {
    isMentee() {
      return this.$can('read', 'mentee')
    },
    translatedFieldsOfSupport: {
      get() {
        const translatedFieldsOfSupport = []
        this.fieldsOfSupport.forEach(
          fieldOfSupportData => translatedFieldsOfSupport.push({
            value: fieldOfSupportData.value,
            text: this.$t(fieldOfSupportData.text)
          })
        )
        return translatedFieldsOfSupport
      },
      set(newValue) {
        this.fieldsOfSupport = this.fieldOfSupportOptions.filter(option => newValue.filter(v => v.value === option.value).length > 0)
      }
    },
    translatedFieldOfSupportOptions() {
      const translatedFieldOfSupportOptions = []
      this.fieldOfSupportOptions.forEach(
        fieldOfSupportData => translatedFieldOfSupportOptions.push({
          value: fieldOfSupportData.value,
          text: this.$t(fieldOfSupportData.text)
        })
      )
      return translatedFieldOfSupportOptions
    }
  },
  setup() {
    const { getFieldsOfSupport, fieldOfSupportOptions } = useFieldOfSupportRepository()
    getFieldsOfSupport()

    return {
      fieldOfSupportOptions
    }
  },
  created() {
    if(this.mentor) {
      this.mentoringReason = this.mentor.reason_for_mentoring
      this.menteeWishlist = this.mentor.mentee_wishlist
      this.mentor.fields_of_support.forEach(
        field_of_support => {
          this.fieldsOfSupport.push({value: field_of_support.id, text: field_of_support.name
        })
      })
    }
    else if(this.isMentee) {
      this.professionalExperience = this.$store.getters['user/getUserData'].professional_experience
    }
  },
  methods: {
    isValid() {
      return this.$refs.mentorRoleObserver.validate().then(success => success)
    },
    collectData() {
      const data = {
        reason_for_mentoring: this.mentoringReason,
        mentee_wishlist: this.menteeWishlist,
        fields_of_support: this.fieldsOfSupport.map(selectedFieldOfSupport => selectedFieldOfSupport.value),
      }
      if(this.isMentee) {
        data.professional_experience = this.professionalExperience,
        data.mentoring_experience = this.mentoringExperience
      }
      return data
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
