<template>
  <div>
    <b-form-textarea
      v-model="input"
      :state="valueLength <= maxChars ? null : false"
      :rows="rows"
      no-resize
    />
    <small
      class="textarea-counter-value float-right"
      :class="valueLength >= maxChars ? 'bg-danger' : ''"
    >
      <span class="char-count">{{ valueLength }}</span> / {{ maxChars }}
    </small>
  </div>
</template>

<script>
import {
  BFormTextarea
} from 'bootstrap-vue'

export default {
  components: {
    BFormTextarea
  },
  props: {
    value: {
      default: null,
      required: true
    },
    size: {
      type: String,
      default: 'lg'
    },
    maxChars: {
      type: Number,
      required: true
    },
    rows: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {

    }
  },
  computed: {
    input: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    valueLength() {
      return this.value ? this.value.length : 0
    }
  }
}
</script>

<style lang="scss" scoped>
.textarea-counter-value {
  background-color: grey;
}
</style>
