<template>
  <div>
    <b-input-group>
      <b-form-textarea
        v-model="input"
        :state="valueLength <= maxChars ? null : false"
        rows="6"
        no-resize
      />
      <b-input-group-append v-if="appendFlag">
        <b-input-group-text>
          {{ selectedLocale.name }}
        </b-input-group-text>
      </b-input-group-append>
    </b-input-group>
    <small
      class="textarea-counter-value float-right"
      :class="valueLength >= maxChars ? 'bg-danger' : ''"
    >
      <span class="char-count">{{ valueLength }}</span> / {{ maxChars }}
    </small>
  </div>
</template>

<script>
import {
  BFormTextarea,
  // BImg,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupText
} from 'bootstrap-vue'
import { useLocales } from '@mentoring-platform/composables'

export default {
  components: {
    BFormTextarea,
    // BImg,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText
  },
  props: {
    value: {
      default: null,
      required: true
    },
    size: {
      type: String,
      default: 'lg'
    },
    locale: {
      type: String,
      default: 'gr'
    },
    appendFlag: {
      type: Boolean,
      required: false
    },
    maxChars: {
      type: Number,
      required: true
    }
  },
  data() {
    return {

    }
  },
  computed: {
    input: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    valueLength() {
      return this.value ? this.value.length : 0
    },
    selectedLocale() {
      return this.locales.find(locale => locale.locale === this.locale)
    }
  },
  setup() {
    const { locales } = useLocales()

    return {
      locales,
    }
  }
}
</script>

<style lang="scss" scoped>
.textarea-counter-value {
  background-color: grey;
}
</style>
