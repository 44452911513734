<template>
  <div>
    <validation-observer ref="mentorProfileObserver">
      <b-row v-if="isMentee">
        <b-col>
          <b-form-group>
            <label for="i-name">
              {{ $t('Name') }} <required-field />
            </label>
            <div
              v-for="(locale, index) in selectedLocales"
              :key="index"
              :class="index < selectedLocales.length - 1 ? 'mb-1' : ''"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="name[locale]"
                rules="required|min:2|max:50"
                mode="lazy"
              >
                <localized-input
                  :id="'i-name' + index"
                  v-model="name[locale]"
                  size="lg"
                  :locale="locale"
                  :append-flag="selectedLocales.length > 1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </b-form-group>
        </b-col>
        <b-col xs="12" sm="6">
          <b-form-group>
            <label for="i-surname">
              {{ $t('Surname') }} <required-field />
            </label>
            <div
              v-for="(locale, index) in selectedLocales"
              :key="index"
              :class="index < selectedLocales.length - 1 ? 'mb-1' : ''"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="surname[locale]"
                rules="required|min:3|max:50"
                mode="lazy"
              >
                <localized-input
                  :id="'i-surname' + index"
                  v-model="surname[locale]"
                  size="lg"
                  :locale="locale"
                  :append-flag="selectedLocales.length > 1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="isMentee">
        <b-col md="6">
          <b-form-group>
            <label for="i-professional-title">
              {{ $t('ProfessionalTitle') }} <required-field />
            </label>
            <div
              v-for="(locale, index) in selectedLocales"
              :key="index"
              :class="index < selectedLocales.length - 1 ? 'mb-1' : ''"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="professionalTitle[locale]"
                rules="required|max:200"
                mode="lazy"
              >
                <localized-input
                  :id="'i-professional-title' + index"
                  v-model="professionalTitle[locale]"
                  size="lg"
                  :locale="locale"
                  :append-flag="selectedLocales.length > 1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="mentor" class="mb-2">
        <b-col>
          <b-card-title class="card-title font-weight-bolder">
            {{ getMentorVisibleFullName(mentor) }}
          </b-card-title>
          <b-card-text v-if="mentor.is_mentor && mentor.is_coach">
            {{ $t('Mentor') }} / {{ $t('Coach') }}
          </b-card-text>
          <b-card-text v-else-if="mentor.is_mentor">
            {{ $t('Mentor') }}
          </b-card-text>
          <b-card-text v-else>
            {{ $t('Coach') }}
          </b-card-text>
        </b-col>
      </b-row>
      <b-row v-if="mentor">
        <b-col md="6" class="mb-3">
          <b-media no-body>
            <b-media-aside>
              <b-link>
                <b-img
                  ref="previewEl"
                  class="grayscale-img"
                  rounded
                  :src="photoAsBase64"
                  height="80"
                />
              </b-link>
              <!--/ avatar -->
            </b-media-aside>

            <b-media-body class="mt-75 ml-75">
              <!-- upload button -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="$refs.refInputEl.$el.click()"
              >
                {{ $t('Upload') }}
              </b-button>
              <validation-provider
                ref="photo-validator"
                v-slot="{ errors }"
                name="photo"
                rules="size:1024|min_dimensions:300,300"
                mode="passive"
              >
                <b-form-file
                  ref="refInputEl"
                  v-model="photo"
                  accept="image/jpeg, image/png"
                  :hidden="true"
                  plain
                  size="lg"
                  @change="newPhotoSelected"
                />
                <!--/ upload button -->

                <!-- reset -->
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  size="sm"
                  class="mb-75 mr-75"
                  @click.prevent="resetForm"
                >
                  {{ $t('Reset') }}
                </b-button>
                <!--/ reset -->
                <b-card-text>
                  {{ $t('Allowed JPG or PNG. Max size of 1024kB') }}
                </b-card-text>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
      <b-row v-else-if="!showOnlyMultilanguageFields">
        <b-col md="6">
          <b-form-group :description="$t('The photo will appear in your profile in the list of mentors')">
            <validation-provider
              ref="photo-validator"
              v-slot="{ errors }"
              name="photo"
              rules="required|size:1024|min_dimensions:300,300"
              mode="passive"
            >
              <label for="i-photo">{{ $t('Photo') }} ({{ $t('Photo specs') }}) <required-field /></label>
              <b-form-file
                v-model="photo"
                id="i-photo"
                placeholder="JPG, PNG"
                accept="image/jpeg, image/png"
                @change="newPhotoSelected"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group>
            <label for="i-mentor-description">
              {{ $t('Mentor description') }} <required-field />
            </label>
            <b-form-text>
              {{ $t('Mentor description more') }}
            </b-form-text>
            <div
              v-for="(locale, index) in selectedLocales"
              :key="index"
              :class="index < selectedLocales.length - 1 ? 'mb-1' : ''"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="mentorDescription[locale]"
                rules="required|max:1400"
                mode="lazy"
              >
                <localized-textarea
                  :id="'i-mentor-description' + index"
                  v-model="mentorDescription[locale]"
                  :locale="locale"
                  :append-flag="selectedLocales.length > 1 || forceFlags"
                  :max-chars="1400"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="!showOnlyMultilanguageFields">
        <b-col>
          <label>
            {{ $t('With which link do you want your profile related to?') }}
          </label>
          <b-form-text class="text-muted">
            {{ $t('Mentor links explanation') }}
          </b-form-text>
        </b-col>
      </b-row>
      <template v-for="(linkData, index) in mentorLinks">
        <b-row
          v-if="linkData.visible && !showOnlyMultilanguageFields"
          :key="index"
        >
          <b-col md="6">
            <validation-provider
              v-slot="{ errors }"
              :name="'mentorLink' + index"
              rules="required|url"
              mode="lazy"
            >
              <b-form-group>
                <b-form-input
                  v-model="mentorLinks[index].url"
                  type="text"
                  placeholder="https://"
                  size="lg"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="index === visibleMentorLinksCount - 1"
            class="d-flex align-items-start"
          >
            <b-button
              v-if="canRemoveLink"
              variant="outline-danger"
              class="mb-1"
              @click="removeLink"
            >
              <i class="feather icon-x" />{{ $t('Remove') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <b-row v-if="!showOnlyMultilanguageFields">
        <b-col>
          <b-button
            v-if="canAddLink"
            variant="outline-primary"
            @click="addLink"
          >
            <i class="feather icon-plus" />{{ $t('Add') }}
          </b-button>
        </b-col>
      </b-row>
      <b-row v-if="isMentee" class="mt-2">
        <b-col>
          <b-form-checkbox
            v-model="giveConsent"
            value="1"
          >
            {{ $t('MentorGiveConsent') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row v-if="isMentee">
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="acceptTermsAndConditions"
            :rules="{
              required: {
                allowFalse: false
              }
            }"
            mode="lazy"
          >
            <b-form-checkbox
              v-model="acceptTermsAndConditions"
              value="1"
            >
              <div>
                {{ $t('I agree to') }}
                <b-link :to="{ name: 'mentoring-terms' }" target="_blank">{{ $t('the terms and conditions of WoT') }}</b-link>
                {{ $t('and') }}
                <b-link :to="{ name: 'privacy-policy' }" target="_blank">{{ $t('the privacy policy') }}</b-link>
                {{ $t('as a mentor') }} <required-field />
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-checkbox>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
    <image-cropper-modal ref="image-cropper-modal" />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BCardText,
  BCol,
  BFormCheckbox,
  BFormFile,
  BFormInput,
  BFormGroup,
  BFormText,
  BFormTextarea,
  BCardTitle,
  BImg,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BRow
} from 'bootstrap-vue'
import { required, url, max, size, min_dimensions } from '@mentoring-platform-validations'
import ImageCropperModal from '@mentoring-platform/views/components/blocks/ImageCropperModal.vue'
import RequiredField from '@mentoring-platform/views/components/blocks/RequiredField.vue'
import Ripple from 'vue-ripple-directive'
import LocalizedInput from '@mentoring-platform/views/components/blocks/LocalizedInput.vue'
import LocalizedTextarea from '@mentoring-platform/views/components/blocks/LocalizedTextarea.vue'
import { useMentorHelpers } from '@mentoring-platform/composables'

export default {
  components: {
    BButton,
    BCardText,
    BCol,
    BFormCheckbox,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormText,
    BFormTextarea,
    BCardTitle,
    BImg,
    BLink,
    BMedia,
    BMediaAside,
    BMediaBody,
    BRow,
    ImageCropperModal,
    LocalizedInput,
    LocalizedTextarea,
    RequiredField,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    mentor: {
      type: Object
    },
    showOnlyMultilanguageFields: {
      type: Boolean,
      default: false
    },
    forceFlags: {
      type: Boolean,
      default: false
    },
    initialLocales: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      name: {
        gr: null,
        en: null
      },
      surname: {
        gr: null,
        en: null
      },
      professionalTitle: {
        gr: null,
        en: null
      },
      photo: null,
      photoAsBase64: null,
      mentorDescription: {
        gr: null,
        en: null
      },
      mentorLinks: [
        { url: null, visible: true },
        { url: null, visible: false },
        { url: null, visible: false },
        { url: null, visible: false },
        { url: null, visible: false },
        { url: null, visible: false },
        { url: null, visible: false },
        { url: null, visible: false },
        { url: null, visible: false },
        { url: null, visible: false },
      ],
      acceptTermsAndConditions: false,
      giveConsent: false,
      reader: null,
      selectedLocales: []
    }
  },
  computed: {
    isMentee() {
      return this.$can('read', 'mentee')
    },
    visibleMentorLinksCount() {
      return this.mentorLinks.filter(mentorLink => mentorLink.visible).length
    },
    canAddLink() {
      return this.visibleMentorLinksCount < 10
    },
    canRemoveLink() {
      return this.visibleMentorLinksCount > 1
    },
  },
  created() {
    this.reader = new FileReader()
    this.reader.onloadend = () => {
      this.photoAsBase64 = this.reader.result
    }

    if (this.mentor) {
      this.setLocales(this.mentor.supported_languages)
      this.mentorDescription = this.mentor.translatable_fields.description
      this.photoAsBase64 = this.mentor.user.photo_full_path
    } else if (this.initialLocales.length) {
      this.setLocales(this.initialLocales)
    }
    if(this.mentor?.links) {
      this.mentor.links.forEach((link, index) => {
        this.mentorLinks[index].url = link.url
        this.mentorLinks[index].visible = true
      })
    }
  },
  beforeMount() {
    this.$bus.$on('image-cropped', this.setImage)
    this.$bus.$on('image-not-cropped', this.resetForm)
    this.$bus.$on('mentor-registration-locales-filled', this.setLocales)
    this.$bus.$on('mentor-locales-changed', this.setLocales)
  },
  beforeDestroy() {
    this.$bus.$off('image-cropped', this.setImage)
    this.$bus.$off('image-not-cropped', this.resetForm)
    this.$bus.$off('mentor-registration-locales-filled', this.setLocales)
    this.$bus.$off('mentor-locales-changed', this.setLocales)
  },
  methods: {
    isValid() {
      return this.$refs.mentorProfileObserver.validate().then(success => success)
    },
    newPhotoSelected(event) {
      let selectedImage = event.target.files[0]
      this.$refs['image-cropper-modal'].show(selectedImage)
    },
    setImage(newImageFile) {
      this.photo = newImageFile
      this.reader.readAsDataURL(this.photo)
      this.$refs['photo-validator'].validate()
    },
    collectData() {
      const mentorDescription = {}
      this.selectedLocales.forEach(language => {
        mentorDescription[language] = this.mentorDescription[language]
      })

      const data = {
        photo: this.photo ? this.photoAsBase64 : null,
        mentor_description: mentorDescription,
        mentor_links: this.mentorLinks.filter(linkField => linkField.visible && linkField.url && linkField.url.length > 0).map(linkField => linkField.url),
      }
      if (this.isMentee) {
        data.name = this.name
        data.surname = this.surname
        data.professional_title = this.professionalTitle
        data.give_consent = this.giveConsent
      }
      return data
    },
    setLocales(locales) {
      this.selectedLocales = locales
      if (this.isMentee && this.selectedLocales.length === 1) { // mentee creates mentor account
        const userData = this.$store.getters['user/getUserData']
        this.name[this.selectedLocales[0]] = userData.name
        this.surname[this.selectedLocales[0]] = userData.surname
        this.professionalTitle[this.selectedLocales[0]] = userData.professional_title
      }
    },
    addLink() {
      this.mentorLinks.find(mentorLink => !mentorLink.visible).visible = true
    },
    removeLink() {
      const visibleLinks = this.mentorLinks.filter(mentorLink => mentorLink.visible)
      visibleLinks[visibleLinks.length - 1].url = null
      visibleLinks[visibleLinks.length - 1].visible = false
    },
    resetForm() {
      this.photo = null
      this.photoAsBase64 = this.mentor ? this.mentor.user.photo_full_path : null
      this.$refs['photo-validator'].reset()
    },
  },
  setup() {
    const { getMentorVisibleFullName } = useMentorHelpers()

    return {
      getMentorVisibleFullName
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
