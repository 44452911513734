/* eslint-disable import/no-extraneous-dependencies */
import { parse, isValid, format as formatDate } from 'date-fns'
/* eslint-disable import/no-extraneous-dependencies */
/* date-fns is not in our package.json but it is installed via vee-validate */

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorUrlValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})(:[0-9]{1,5})?(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?$/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}

/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
export const validatorImageDimensions = async (files, { width, height }) => {
  const validateImage = (file, width, height) => {
    const URL = window.URL || window.webkitURL
    return new Promise(resolve => {
      const image = new Image()
      image.onerror = () => resolve({ valid: false })
      image.onload = () => resolve({
        valid: image.width >= Number(width) && image.height >= Number(height),
      })

      image.src = URL.createObjectURL(file)
    })
  }
  if (!Array.isArray(files)) {
    files = [files]
  }
  const list = []
  for (let i = 0; i < files.length; i++) {
    // if file is not an image, reject.
    if (!/\.(jpg|svg|jpeg|png|bmp|gif)$/i.test(files[i].name)) {
      return false
    }
    list.push(files[i])
  }
  const isValid = await Promise.all(list.map(file => validateImage(file, width, height))).then(results => results.filter(res => !res.valid).length === 0)
  return isValid
}
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

export const validatorDateFormat = (date, { format }) => {
  if (typeof date !== 'string') {
    return false
  }
  const parsed = parse(date, format, new Date())
  // if date is not valid or the formatted output after parsing does not match the string value passed in (avoids overflows)
  if (!isValid(parsed) || formatDate(parsed, format) !== date) {
    return false
  }
  return true
}

export const validatorWorkingExperience = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }

  const dateParts = val.split('-')
  if (dateParts.length < 2) {
    return false
  }

  return parseInt(dateParts[1], 10) >= 1970
}
